* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

.background {
  width: 100%;
  height: 100vh;
  /* background-image: radial-gradient(#000 1px, transparent 0),
    radial-gradient(#000 1px, transparent 0);
  background-size: 52px 52px;
  background-position: 0 15px, 26px 40px; */
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
  }

  /* .background {
    background-image: radial-gradient(white 1px, transparent 0),
      radial-gradient(white 1px, transparent 0);
  } */
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.container {
  font-family: 'Comic Neue', cursive;
  position: fixed;
  bottom: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background: linear-gradient(
    90deg,
    rgba(232, 91, 191, 0.4) 0%,
    rgba(128, 238, 189, 0.4) 100%
  );
}

.container .content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media only screen and (max-width: 600px) {
  .container .content {
    grid-gap: 5px;
    font-size: 12px;
  }
}

a {
  font-weight: 600;
  color: #4948e5;
  text-decoration: none;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.25s;
}

a:hover {
  opacity: 1;
}

.sound {
  position: absolute;
  right: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .sound {
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    right: 0;
    top: -5rem;
  }
}

.sound img {
  height: 1.5rem;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.sound img:hover {
  opacity: 1;
}

#music-by {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Arial, "Sans-serif";
  font-size: 12px;
  transition: opacity 0.25s;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed;
  font-family: 'Comic Neue', cursive;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9); /* Dark background */
  color: #f1f1f1;
  z-index: 1000; /* Ensure it appears on top */
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
  overflow: auto; /* Allow scrolling if content is too long */
}

.modal p{
  margin-top: 15px;
  max-width: 500px;
  text-align: justify;
  padding: 0 25px;
}

.modal.active {
  display: flex; /* Show the panel */
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  border: none;
  background: none;
}

.stars-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center !important;
}

.stars-content p {
  text-align: center !important;
}

.stars-content h1 {
  color: yellow;
}

/* Top left completed quests */
#completionTracker {
  opacity: 0.8;
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: 'Comic Neue', cursive;
  font-size: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.completed {
  color: #FFD700; /* Gold color */
  transform: scale(1.1);
}